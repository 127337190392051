import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyAdviceDialogComponent } from '../privacy-advice-dialog/privacy-advice-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private matDialog: MatDialog) {}

  openPrivacyAdviceDialog(): void {
    this.matDialog.open(PrivacyAdviceDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '95vh',
      height: '100%',
      width: '95%',
    });
  }
}
