// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'development',
  amplifySettings: {
    aws_appsync_graphqlEndpoint: 'https://mwob643iurgildtfefwf2ewyje.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-unnocleygzd7ddwnrxdalypdae',
  },

  endpoints: {
    returnTo: 'https://dev.client.happyland.mx.innervycs.com',
    secure: 'https://qa.secure.api.happyland.mx.innervycs.com',
    invoice: 'https://qa.invoice.api.happyland.mx.innervycs.com',

    paypal: 'https://qa.paypal.api.happyland.mx.innervycs.com',
    openpay: 'https://qa.openpay.api.happyland.mx.innervycs.com',
    mercadoPago: 'https://qa.mercadopago.api.happyland.mx.innervycs.com',
  },
  maintenance: false,
  paypal: {
    clientId: 'AX60cWauHPj8chv8roINsBETdJy5riS1QMCIE7fPwcEnjRyXm_pBaJzccnKrI0hZwHQNVRfkSTR3C5ho',
  },

  documents: {
    privacyNotice: 'https://src-qa-web-assets-mx.s3.amazonaws.com/documents/PRIVACY_NOTICE.pdf',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
