import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-advice-dialog',
  templateUrl: './privacy-advice-dialog.component.html',
  styleUrls: ['./privacy-advice-dialog.component.scss'],
})
export class PrivacyAdviceDialogComponent implements OnInit {
  privacyNoticeDocument: string = environment.documents.privacyNotice;

  constructor() {
    this.privacyNoticeDocument = environment.documents.privacyNotice;
  }
  public ngOnInit(): void {}

  pagesLoaded(event: any) {
    console.log('PDF loaded');
  }
}
