import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://7f21bd494dcada12e1c534ef39e62486@o4505199616458752.ingest.sentry.io/4505828333453312',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost:4200',
        'https://recargasqr.happyland.com.mx/',
        'https://dev.client.happyland.mx.innervycs.com/',
        'https://alpha.client.happyland.mx.innervycs.com/',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Documentación de esta característica en https://docs.sentry.io/platforms/javascript/session-replay/configuration/
    new Sentry.Replay({
      // Fija el tiempo mínimo y máximos en mili segundos para evitar consumirse toda la cuota
      minReplayDuration: 5000,
      maxReplayDuration: 15000,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in
  // development and then sample at a lower rate in production.
  replaysSessionSampleRate: environment.production ? 0.1 : 1.0,
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: environment.production ? 1.0 : 0.5,
  // Fijar entorno
  environment: environment.environment,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
