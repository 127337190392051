import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    data: { title: 'Happyland Recarga Express' },
    redirectTo: '/portal',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'card-error',
        loadChildren: () => import('./pages/error-card-page/error-card-page.module').then((m) => m.ErrorCardPageModule),
      },
      {
        path: 'server-error',
        loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
      },
      {
        path: 'portal',
        loadChildren: () => import('./pages/promotion-portal/promotion-portal.module').then((m) => m.PromotionPortalModule),
      },
      {
        path: 'portal/:card',
        loadChildren: () => import('./pages/promotion-portal/promotion-portal.module').then((m) => m.PromotionPortalModule),
      },
      {
        path: 'payment-page',
        loadChildren: () => import('./pages/payment-portal/payment-portal.module').then((m) => m.PaymentPortalModule),
      },
      {
        path: 'payment-complete',
        loadChildren: () => import('./pages/payment-complete/payment-complete.module').then((m) => m.PaymentCompleteModule),
      },
      {
        path: 'refund',
        loadChildren: () => import('./pages/refund-page/refund-page-routing.module').then((m) => m.RefundPageRoutingModule),
      },
      {
        path: 'paypal',
        loadChildren: () => import('./pages/paypal-page/paypal-page.module').then((m) => m.PaypalPageModule),
      },
      {
        path: 'openpay',
        loadChildren: () => import('./pages/openpay-page/openpay-page.module').then((m) => m.OpenpayPageModule),
      },
      {
        path: 'mercadopago',
        loadChildren: () => import('./pages/mercadopago-page/mercadopago-page.module').then((m) => m.MercadopagoPageModule),
      },
      {
        path: 'my-happy-plus',
        loadChildren: () => import('./pages/my-happy-profile/my-happy-profile.module').then((m) => m.MyHappyProfileModule),
      },
      {
        path: 'historical',
        loadChildren: () => import('./pages/historical-portal/historical-portal.module').then((m) => m.HistoricalPortalModule),
      },
      {
        path: 'special-promotions',
        loadChildren: () => import('./pages/special-promotions-portal/special-promotions-portal.module').then((m) => m.SpecialPromotionsPortalModule),
      },
      {
        path: 'waiver',
        loadChildren: () => import('./pages/temporary-waiver/temporary-waiver.module').then((m) => m.TemporaryWaiverModule),
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
